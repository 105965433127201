@import '@/scss/variables.scss'
@import '~vuetify/src/styles/styles.sass'

.descricao
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)
  position: absolute
  color: back
  top: 45px

.k-sub-file
  color: rga(0,0,0,.54)
  bottom: -2.25em

.fade-enter-active, .fad-leave-active
  transition: opaity .5s


.fade-enter, .fade-leave-o
  opacity: 0
